import { Injectable } from '@angular/core';
import {
  Config,
  EntityResponse,
  MoveSpotsRequest,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  Request,
  Tariff,
  TariffsData,
  TariffsDetailView,
  TariffsFormView
} from '../../core/interfaces';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SspService } from '../../core/services/ssp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TariffsService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private sspService: SspService
  ) {}

  getTariffsGrid(request: Request): Observable<TariffsData> {
    return this.http.get<TariffsData>(`${this.config.ssp_endpoint}/tariff/grid`, { params: this.sspService.prepareRequest(request) });
  }

  /**
   * Get tariff form data
   *
   * @return { Observable<TariffsFormView> }
   * @param {Request} request
   */
  getTariffForm(request: Request): Observable<TariffsFormView> {
    return this.http.get<TariffsFormView>(`${this.config.ssp_endpoint}/tariff/getForm`, {
      params: this.sspService.prepareRequest(request)
    });
  }

  /**
   * Get tariff detail info data
   *
   * @return { Observable<TariffsDetailView> }
   * @param {Request} request
   */
  getTariffDetails(request: Request): Observable<TariffsDetailView> {
    return this.http.get<TariffsDetailView>(`${this.config.ssp_endpoint}/tariff/get`, { params: this.sspService.prepareRequest(request) });
  }

  changeTariffsStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.ssp_endpoint}/tariffs/status`, data, { headers });
  }

  addTariff(data: Tariff): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.ssp_endpoint}/tariff`, data, { headers });
  }

  editTariff(data: Tariff): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.ssp_endpoint}/tariff`, data, { headers });
  }

  getSpotsByTariffs(data: Request): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${this.config.ssp_endpoint}/spot/list/by-tariffs`, data, { headers });
  }

  moveSpots(data: MoveSpotsRequest): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.ssp_endpoint}/spot/tariff/transfer`, data, { headers });
  }
}
